export const regUrl =
  /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(\:[0-9]+)?)(\/[a-zA-Z0-9%_.~+-]+)*\/?(\?[\u4E00-\u9FA5\w%_.,~+-=&{}]*)?(#[\u4E00-\u9FA5\w_-{}]+)?$/;
export const rules = {
  accountId: [
    {
      required: true,
      message: '请选择广告账户',
    },
  ],
  promotionLink: [
    {
      required: true,
      message: '请输入推广连接',
    },
    {
      validator(rule, value, callback) {
        if (value && !regUrl.test(value)) {
          return callback(new Error('请输入合法域名'));
        }
        callback();
      },
      trigger: ['blur'],
    },
  ],
  sale: [
    {
      required: true,
      message: '请输入合法推广连接并回车查询',
    },
  ],
  site: [
    {
      required: true,
      message: '请输入合法推广连接并回车查询',
    },
  ],
};
