<template>
  <div class="bg-white mt-5 p-5">
    <el-form
      label-position="left"
      size="medium"
      label-width="80px"
      :model="formData"
      :rules="rules"
      ref="form"
    >
      <el-form-item
        label="广告账户"
        prop="accountId"
      >
        <account :currentAccount="createAdAccount"></account>
      </el-form-item>
      <el-form-item
        label="推广连接"
        prop="promotionLink"
      >
        <!-- <el-row>
          <el-col :span="24"> -->
        <el-input
          v-model="formData.promotionLink"
          @blur="onChangePromotionLink"
          v-loading="promotionLinkLoading"
          @keyup.enter.native="
            (e) => {
              e.target.blur();
            }
          "
        ></el-input>
        <!-- </el-col>
          <el-col :span="2"> -->
        <el-button
          type="text"
          @click="openLink"
          class="w-[80px]"
          >预览</el-button
        >
        <!-- </el-col>
        </el-row> -->
      </el-form-item>
      <el-form-item
        label="商品id"
        prop="sale"
      >
        <sale :sale-info="formData.sale"></sale>
      </el-form-item>
      <el-form-item
        label="站点信息"
        prop="site"
      >
        <site
          v-model="formData.site"
          disabled
        ></site>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import selectAccount from '@/components/create/selectAccount.vue';
import account from '@/views/google/create/components/campaignInfo/components/account.vue';
import sale from '@/components/create/sale.vue';
import site from '@/components/create/site.vue';
import { promoteInfo } from '@/api/google/create.js';
import { rules, regUrl } from './index';
import { mapState } from 'vuex';

export default {
  props: {
    value: Object,
    currentAccount: Object,
  },
  components: {
    account,
    sale,
    site,
  },
  data() {
    return {
      promotionLinkLoading: false,
      formData: {
        accountId: '',
        promotionLink: '',
      },
      rules: rules,
      loadingPixel: false,
      pixelList: [],
    };
  },
  computed: {
    ...mapState('google', ['createAdAccount']),
  },
  methods: {
    onChangePromotionLink() {
      if (!this.formData.promotionLink || !this.formData.promotionLink.trim()) {
        this.$set(this.formData, 'sale', null);
        this.$set(this.formData, 'site', null);
      }
      let params = {
        promotionLink: this.formData.promotionLink.trim(),
        utmSourceConfig: this.createAdAccount.utmSourceConfig,
      };
      if (!regUrl.test(this.formData.promotionLink)) {
        return;
      }
      //   if (this.value.promotionInfo.promotionLink == this.formData.promotionLink) return;

      this.promotionLinkLoading = true;
      this.$set(this.formData, 'sale', null);
      this.$set(this.formData, 'site', null);
      promoteInfo(params)
        .then((res) => {
          if (res.code == 0) {
            this.$set(this.formData, 'site', { ...res.data.site, name: res.data.site.domain });
            this.$set(this.formData, 'sale', { ...res.data.sale, id: res.data.sale.saleId });
          } else {
            this.$set(this.formData, 'sale', null);
            this.$set(this.formData, 'site', null);
          }
          this.onChange();
          this.$emit('changePromotionLink', this.formData.promotionLink);
        })
        .finally(() => {
          this.promotionLinkLoading = false;
        });
    },
    // 打开链接验证链接是否能打开
    openLink() {
      if (!this.formData.promotionLink) return;
      window.open(this.formData.promotionLink);
    },
    onChange() {
      const { accountId, promotionLink, sale, site } = this.formData;
      const newValue = {
        ...this.value,
        accountId,
        promoteInfo: {
          promotionLink,
          sale,
          site,
        },
      };
      this.$emit('input', {
        ...newValue,
      });
      this.$emit('change', {
        ...newValue,
      });
    },
    // 对外提供的验证
    validate() {
      return this.$refs.form.validate();
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        const { accountId, promoteInfo } = val;
        this.formData = {
          accountId: this.createAdAccount.id,
          promotionLink: promoteInfo.promotionLink,
          sale: promoteInfo.sale,
          site: promoteInfo.site,
        };
      },
    },
  },
};
</script>
