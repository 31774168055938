<template>
  <div>
    <el-input
      v-model="createAdAccount.name"
      placeholder=""
      disabled
    ></el-input>
    <div>
      <span v-if="createAdAccount.isSetup">
        <span v-if="createAdAccount.ctsCtStatus == 'CONVERSION_TRACKING_MANAGED_BY_SELF'">
          <i class="el-icon-success color-success mr-5"></i>广告账户已完成配置
        </span>
        <span v-else>
          <el-tooltip
            content="转化目标和操作由经理账号进行管理的， API回传转化不生效。请前往经理账户下，在设置-->子账户设置中将Google Ads转化账户设置为客户"
          >
            <span><i class="el-icon-warning color-warning mr-5"></i>广告账户已完成配置但未生效</span>
          </el-tooltip>
        </span>
        <el-button
          type="text"
          @click="toggleSetupAccountVisible(true)"
          class="ml-10"
          >重新配置</el-button
        >
      </span>
      <span v-else>
        <i class="el-icon-warning color-warning mr-5"></i>广告账户未进行API配置
        <el-button
          type="text"
          @click="toggleSetupAccountVisible(true)"
          >去配置</el-button
        >
      </span>
    </div>
    <setupAccount
      :visible="setupAccountVisible"
      @toggleVisible="toggleSetupAccountVisible"
      :account_ids="[createAdAccount.id]"
      :settinginfo="{ ...createAdAccount }"
      :isOne="true"
    ></setupAccount>
  </div>
</template>
<script>
import setupAccount from '@/views/google/accountManagement/components/setupAccount'; // 转化设置
import { mapState } from 'vuex';
import { findAccountById } from '@/api/google/account.js';
export default {
  props: {
    currentAccount: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('google', ['createAdAccount']),
  },
  data() {
    return {
      setupAccountVisible: false,
      setupInfo: {
        isSetup: true,
      },
      account: {
        name: '测试账户名称',
        id: '454512154',
      },
    };
  },
  components: {
    setupAccount,
  },
  mounted() {
    this.checkAccountSetup();
  },
  methods: {
    // 显示/隐藏账户配置弹窗
    toggleSetupAccountVisible(flag) {
      this.setupAccountVisible = flag;
    },
    // 获取当前账户API配置情况
    checkAccountSetup() {
      let params = {
        accountId: this.createAdAccount.id,
      };
      findAccountById(params).then((res) => {
        if (res.code == 0) {
          let data = {
            id: res.data.accountId,
            name: res.data.accountName,
            canManagerClient: res.data.canManagerClient,
            canNotManagerClient: !res.data.canManagerClient,
            rowKey: res.data.credentialId + '-' + res.data.accountId,
            utmSourceConfig: res.data.utmSourceConfig,
            isSetup: res.data.hasSetup,
            ctsCtStatus: res.data.ctsCtStatus,
            _credentialAccount: {
              adaccount: {
                id: res.data.accountId,
                name: res.data.accountName,
                canManagerClient: res.data.canManagerClient,
              },
              hide: '',
              error: '',
              invalid: res.data.invalid,
            },
          };
          this.$store.commit('google/setCreateAdAccount', data);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
