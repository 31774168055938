<template>
  <div class="bg-white relative">
    <el-tabs
      v-model="current"
      type="card"
      ref="tabs"
      @tab-remove="deleteItem"
      :before-leave="beforeLeave"
    >
      <el-tab-pane
        v-for="(audience, index) in value"
        :key="'label_' + index"
        :name="index + ''"
        closable
      >
        <span slot="label">
          <i
            class="el-icon-warning text-red-600"
            v-if="errList.includes(index)"
          ></i>
          受众{{ index + 1 }}
          <!-- <i
            class="el-icon-circle-close ml-2 text-zinc-800 hover:text-primary"
            @click="deleteItem(index)"
          ></i> -->
        </span>
        <div
          class="m-6 pr-24"
          :key="index"
        >
          <el-form
            :model="audience"
            label-width="120px"
            :rules="rules"
            ref="form"
          >
            <p class="font-semibold text-[#666] text-[16px]">定向</p>
            <el-form-item
              label="地区"
              prop="groupCriterion.target.locations"
            >
              <div class="flex">
                <el-select
                  multiple
                  filterable
                  default-first-option
                  value-key="country_code"
                  v-model="audience.groupCriterion.target.locations"
                  @change="() => onChangeLocations(audience.groupCriterion.target.locations, index)"
                >
                  <el-option-group
                    v-for="group in countryList"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="item.country_code"
                      :label="item.name_zhcn"
                      :value="item"
                    >
                    </el-option>
                  </el-option-group>
                  <!-- <el-option
                    v-for="item in enumConstants.countries"
                    :key="item.country_code"
                    :label="item.name_zhcn"
                    :value="item"
                  >
                  </el-option> -->
                </el-select>
                <el-button
                  type="text"
                  @click="autoFillLocations(index)"
                  class="!ml-2"
                  >自动填充</el-button
                >
              </div>
            </el-form-item>
            <el-form-item label="语言">
              <el-select
                multiple
                filterable
                default-first-option
                v-model="audience.groupCriterion.target.languages"
              >
                <el-option
                  v-for="languages in enumConstants.languages"
                  :label="languages.nameZhcn"
                  :value="languages.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <p class="font-semibold text-[#666] text-[16px]">受众</p>
            <el-form-item label="受众群体">
              <AudienceGroup
                ref="audienceGroup"
                v-model="audience.groupCriterion.audience.resourceName"
                :accountId="currentAccount.id"
                :defaultList="constData.audienceGroupList"
                :currentAccount="currentAccount"
                @selectAudienceGroup="(v) => selectAudienceGroup(index, v)"
              />
            </el-form-item>
            <el-form-item
              label="性别"
              prop="sex"
            >
              <AudienceSex
                v-model="audience.groupCriterion.audience.genders"
                :disabled="true"
              />
            </el-form-item>
            <el-form-item label="年龄">
              <AudienceAge
                v-model="audience.groupCriterion.audience.ageRange"
                :disabled="true"
              />
            </el-form-item>
            <el-form-item label="自定义细分受众">
              <CustomAudience
                ref="customAudience"
                :currentAccount="currentAccount"
                :defaultList="constData.customAudienceList"
                v-model="audience.groupCriterion.audience.customAudiences"
                :disabled="true"
              />
            </el-form-item>
            <el-form-item label="兴趣和详细受众">
              <InterestAudience
                ref="interestAudience"
                v-model="audience.groupCriterion.audience.userInterests"
                :list="interestList"
                :interestLabelSaveAPI="interestLabelSaveAPI"
                :countryCodes="audience.groupCriterion.target.locations"
                :defaultList="constData.interestLabelQueryList"
                :accountId="currentAccount.id"
                :defaultExpandAll="false"
                :disabled="true"
                @input="(v) => getInterest(index, v)"
              ></InterestAudience>
            </el-form-item>
            <el-form-item label="优化型定位">
              <OptimizePosition
                :value="audience"
                @input="(v) => setAudiencePosition(index, v)"
              />
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane name="add">
        <div
          slot="label"
          @click.stop="addItem"
        >
          <i class="el-icon-circle-plus-outline mr-1" />添加受众
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="absolute z-50 right-6 top-0 h-10 leading-10">
      <div
        slot="label"
        @click.stop="copyItem"
        class="hover:text-primary text-sm leading-10"
      >
        <i class="el-icon-copy-document mr-1" />复制受众
      </div>
    </div>
  </div>
</template>
<script>
import { getAudienceDefault, sexList, interestList } from '../../index.js';
import InterestAudience from './interestAudiences';
import CreateSegmentAudience from '@/views/google/create/components/audience/createSegmentAudience/index.vue';
import OptimizePosition from '@/views/google/create/components/audience/OptimizePosition/index.vue';
import AudienceAge from '@/views/google/create/components/audience/AudienceAge/index.vue';
import AudienceGroup from '@/views/google/create/components/audience/AudienceGroup/index.vue';
import AudienceSex from '@/views/google/create/components/audience/AudienceSex/index.vue';
import CustomAudience from '@/views/google/create/components/audience/CustomAudience/index';
import _ from 'lodash';
import { rules } from './index.js';
import { selectTree } from '@adCommonComponent';
import { saveCommonInterests, listCustomerAudience, commonUseLocations } from '@/api/google/create';
export default {
  components: {
    InterestAudience,
    CreateSegmentAudience,
    selectTree,
    OptimizePosition,
    AudienceAge,
    AudienceGroup,
    AudienceSex,
    CustomAudience,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    currentStep: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
    enumConstants: {
      type: Object,
      default: () => {},
    },
    currentAccount: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // formData: [],
      rules,
      interestList,
      sexList, //性别遍历数据
      current: '0', // 当前受众下标
      interestLabelSaveAPI: saveCommonInterests,
      errList: [], // 验证不通过列表
      options: [],
      constData: {},
      commonLocation: [],
    };
  },
  computed: {
    countryList() {
      return [
        {
          label: '常用',
          options: this.commonLocation,
        },
        {
          label: '地区',
          options: this.enumConstants.countries,
        },
      ];
    },
  },
  methods: {
    addItem() {
      this.value.push(getAudienceDefault());
      this.$emit('input', this.value);
    },
    copyItem() {
      let current = this.value[this.current];
      this.value.push(JSON.parse(JSON.stringify(current)));
      this.$emit('input', this.value);
    },
    deleteItem(index) {
      if (this.value.length == 1) {
        this.$message({
          type: 'warning',
          message: '至少保留一个受众',
        });
        return;
      }
      this.value.splice(index, 1);
      if (this.current >= this.value.length) {
        this.current = `${this.value.length - 1}`;
      }
      this.$emit('input', this.value);
    },
    beforeLeave(active, oldActive) {
      console.log(active, oldActive);
      if (active == 'add') {
        this.addItem();
        return false;
      }
    },
    /**
     * 选择受众群体
     */
    selectAudienceGroup(index, v) {
      if (v) {
        this.value[index].groupCriterion.audience = { ...v };
        this.value[index].groupCriterion.audience.userInterests = v.userInterests || [];
        this.value[index].groupCriterion.audience.customAudiences = v.customAudiences || [];
        this.value[index].groupCriterion.audience.genders = v.genders || [];
        this.$refs.interestAudience[index].interestLabelQuery = [];
      } else {
        this.value[index].groupCriterion.audience = {
          ageRange: {
            minAge: 18,
            maxAge: null,
            undetermined: true,
          }, //年龄
          genders: ['UNDETERMINED', 'MALE', 'FEMALE'], // 性别
          languages: [], // 语言
          userInterests: [], // 兴趣词
          customAudience: [], // 自定义受众
        };
      }
    },
    /* 绑定受众定位的值*/
    setAudiencePosition(index, v) {
      this.$set(this.value, index, { ...this.value[index], ...v });
    },
    // 自动填充地区
    autoFillLocations(index) {
      let val = this.data.promoteInfo.site.countryCode;
      let countries = this.enumConstants.countries.filter((v) => v.country_code == val);
      console.log(countries);
      this.$set(this.value[index].groupCriterion.target, 'locations', countries);
      this.onChangeLocations(countries, index);
    },
    // 修改地区
    onChangeLocations(val, index) {
      console.log(val);
      let languagesDefault = [...new Set(val.map((v) => v.defaultLanguageIds).flat())];
      this.$set(this.value[index].groupCriterion.target, 'languages', languagesDefault);
    },
    getInterest(index, v) {
      console.log(index, v);
      this.$forceUpdate();
    },
    // 对外验证
    validate() {
      let list = [];
      this.errList = [];
      this.$refs.form.map((form, index) => {
        list.push(form.validate().catch(() => index));
      });
      return new Promise((resolve, reject) => {
        Promise.allSettled(list).then((res) => {
          res.map((item) => {
            if (typeof item.value == 'number') {
              this.errList.push(item.value);
              reject();
            }
          });
          resolve();
        });
      });
    },
    clearValidate() {
      if (!this.$refs.form) return;
      let list = [];
      this.errList = [];
      this.$refs.form.map((form, index) => {
        list.push(form.clearValidate());
      });
      return Promise.all(list);
    },
    /*获取下拉框的值*/
    async getConstDataList() {
      await this.$refs.audienceGroup?.[0].getAudienceGroupList();
      await this.$refs.interestAudience?.[0].getInterestLabelQueryList();
      await this.$refs.customAudience?.[0].getCustomAudienceList();
      this.$nextTick(() => {
        this.constData = {
          audienceGroupList: this.$refs.audienceGroup?.[0].audienceGroupList,
          customAudienceList: this.$refs.customAudience?.[0].customAudienceList,
          interestLabelQueryList: this.$refs.interestAudience?.[0].interestLabelQueryList,
        };
        console.log(this.constData, ' this.constData  this.constData ');
      });
    },
    getCommonUseLocations() {
      commonUseLocations({ accountId: this.currentAccount.id }).then((res) => {
        this.commonLocation = res.data;
      });
    },
  },
  created() {},
  watch: {
    currentStep() {
      if (this.currentStep == 2) {
        if (Object.keys(this.constData).length > 0) {
          return;
        }
        this.getCommonUseLocations();
        this.getConstDataList();
      }
    },
  },
};
</script>
