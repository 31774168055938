export const rules = {
  'groupCriterion.target.locations': [
    {
      required: true,
      message: '请选择地区',
    },
    {
      validator(rule, value, callback) {
        if (value.length == 0) {
          return callback(new Error('请选择地区'));
        }
        callback();
      },
      trigger: ['blur'],
    },
  ],
};
