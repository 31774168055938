<template>
  <div class="flex items-end interestWord">
    <span
      class="flex justify-between items-end"
      style="width: 80%"
    >
      <div class="flex-1">
        <selectTree
          :data="list"
          :value="defaultValue"
          :disabled="disabled"
          v-bind="$props"
          :props="{
            children: 'children',
            label: 'label',
            disabled: 'disabled',
          }"
          v-loading="loading"
          checkStrictly
          ref="selectTree"
          class="selectTree"
          @change="onTreeChange"
          @check="onCheck"
        >
          <template slot-scope="{ node, data }">
            <div class="flex justify-between items-center w-full">
              <div>{{ node.label }}</div>
            </div>
          </template>
        </selectTree>
      </div>
      <div class="ml-2">
        <el-select
          placeholder="选择常用"
          v-model="interestLabelQuery"
          value-key="id"
          filterable
          clearable
          multiple
          @change="changeInterestLabel"
          :loading="loadingLabel"
          :disabled="disabled"
        >
          <el-option
            v-for="item in interestLabelQueryList"
            :label="item.title"
            :value="item"
          >
            <div class="flex w-full items-center justify-between">
              <div>{{ item.title }}</div>
              <div @click.stop="deleteLabel(item.id)">
                <i class="el-icon-delete text-zinc-700 hover:text-primary"></i>
              </div>
            </div>
          </el-option>
        </el-select>
      </div>
    </span>

    <el-button
      type="text"
      :disabled="predefinedAudience.length == 0"
      @click="showIntersLabel = true"
      class="ml-2"
      >存为常用</el-button
    >

    <interstLabelSave
      :show.sync="showIntersLabel"
      @close="getInterestLabelQueryList"
      :API="saveCommonInterests"
      nameKey="title"
      :params="{
        accountId: accountId,
        audiences: predefinedAudience,
        type: 'INTEREST',
      }"
    ></interstLabelSave>
  </div>
</template>

<script>
import { interstLabelSave, selectTree, selectTreePropsType } from '@adCommonComponent';
import { listCommonInterests, delCommonInterests, saveCommonInterests } from '@/api/google/create.js';
import { interestLabelDel } from '@/api/snapchat/create.js';
import { mapState } from 'vuex';
import _ from 'lodash';
export default {
  props: {
    ...selectTreePropsType,
    value: {
      type: Array,
      default: () => [],
    },
    props: {
      default: () => ({ children: 'childList', label: 'name' }),
    },
    countryCodes: Array, // 国家列表
    accountId: String | Number, // 账户id
    list: Array,
    defaultList: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: { selectTree, interstLabelSave },
  computed: {
    ...mapState({
      userId: (state) => state.user.userInfo.data.id,
    }),
    defaultValue() {
      return this.value?.map((v) => v + '');
    },
    predefinedAudience() {
      let value = this.value || [];
      let list = this.filterSelectedKeys(this.list).filter((v) => value.includes(v.id));
      return list.map((v) => {
        return {
          audienceId: v.id,
          audienceName: v.label,
        };
      });
    },
  },
  data() {
    return {
      loading: false,
      interestLabelQuery: [], // 选择的常用
      showIntersLabel: false,
      interestLabelQueryList: [], // 常用列表
      loadingLabel: false,
      saveCommonInterests,
    };
  },
  watch: {
    defaultList: {
      handler() {
        this.interestLabelQueryList = this.defaultList;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    isSelectAll(list) {
      const intersection = _.intersection(list, this.predefinedAudience);
      return intersection.length == list.length;
    },
    // 过滤兴趣词选择的项
    filterSelectedKeys(treeData) {
      return _.flatMap(treeData, (item) => {
        const child = item.children ? this.filterSelectedKeys(item.children) : [];
        let { children, ...node } = item;
        return [node, ...child];
      });
    },
    // 选择常用
    changeInterestLabel(value) {
      console.log(value);
      if (!value) return;
      let ids = value.map((v) => {
        return v.audiences.map((k) => k.audienceId);
      });
      console.log(ids);
      this.onChange([...this.value, ...ids.flat()]);
    },
    onTreeChange(value) {
      this.onChange(value);
    },
    onCheck({ checkedKeys, checked, data }) {
      this.onChange(checkedKeys);
    },
    onChange(value) {
      console.log(value);
      this.$emit('input', [...value]);
    },
    // 常用列表
    async getInterestLabelQueryList() {
      let params = {
        accountId: this.accountId,
        // type: 'ACCOUNT',
      };
      this.loadingLabel = true;
      await listCommonInterests(params)
        .then((res) => {
          if (res.code == 0) {
            this.interestLabelQueryList = res.data;
          }
        })
        .finally(() => {
          this.loadingLabel = false;
        });
    },
    deleteLabel(id) {
      let data = {
        id,
      };
      delCommonInterests(data).then((res) => {
        if (res.code == 0) {
          const index = this.interestLabelQuery.findIndex((ele) => ele.id == id);
          if (index >= 0) {
            this.interestLabelQuery.splice(index, 1);
          }
          this.getInterestLabelQueryList();
        }
      });
    },
  },
  mounted() {
    // this.getInterestLabelQueryList();
  },
};
</script>

<style lang="scss" scoped>
.interestWord {
  .el-select {
    width: 100% !important;
  }
}

::v-deep .selectTree .el-input {
  width: 100%;
}
</style>
